import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import CreditCard from "@material-ui/icons/CreditCardOutlined";
import { useTranslate } from "react-polyglot";
import { amber, indigo, yellow } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import red from "@material-ui/core/colors/red";
import { IS_APP_BEHAPPY, IS_APP_STUDYLESS } from "../../constants.js";
import { useBaseData } from "../../Providers/Data/BaseDataProvider.jsx";
import starIcon from "../../assets/yellow-star.svg";
import { useAuth } from "../../Providers/Auth/AuthProvider.jsx";
import HomeTabs from "./HomeTabs.jsx";
import LanguageSelect from "../../SharedComponents/LanguageSelect.jsx";
import LoadingBackdrop from "../../SharedComponents/LoadingBackdrop.jsx";
import FlexboxHorizontal from "../../SharedComponents/FlexboxHorizontal.jsx";
import EventsProvider from "../../Providers/Data/EventsProvider.jsx";
import NewsProvider from "../../Providers/Data/NewsProvider.jsx";
import FlexboxVertical from "../../SharedComponents/FlexboxVertical.jsx";
import PushNotification from "../../SharedComponents/PushNotification.jsx";
import { isNotificationsSupported } from "../../Utils/firebase.js";
import CourseSelect from "../../SharedComponents/CourseSelect.jsx";
import useFavicon from "../../hooks/useFavicon.js";
import InactiveUserBar from "./HomeComponents/InactiveUserBar.jsx";
import AccessDaysWarningBar from "./HomeComponents/AccessDaysWarningBar.jsx";
import useEffectWithPreviousValues from "../../hooks/useEffectWithPreviousValues.js";
import { useDialogs } from "../../Providers/Common/DialogsProvider.jsx";
import ViewportMeta from "../../SharedComponents/ViewportMeta.jsx";

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: theme.palette.primary.main,
  },
  toolbar: {
    justifyContent: "space-between",
  },
  star: {
    marginBottom: 2,
  },
  notification: {
    padding: 5,
    margin: "0 40px 0 0",
  },
  notificationIcon: {
    display: "inline-block",
    position: "relative",
    lineHeight: 0,
    "&::before": {
      content: "''",
      display: "block",
      height: 24,
      width: 24,
      borderRadius: "50%",
      backgroundColor: "#000000",
      position: "absolute",
      top: "0",
      left: "0",
      transformOrigin: "center",
      animation: "scale-up-center 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite both",
    },
    "& .MuiSvgIcon-root": {
      position: "relative",
      color: yellow[600],
    },
  },
  leaderPoints: {
    color: yellow[600],
    fontWeight: 800,
    margin: "0 0 0 6px",
  },
  userName: {
    fontWeight: 800,
    textTransform: "none",
    fontSize: 12,
  },
  menuText: {
    margin: "0 0 0 5px",
  },
  courseButton: {
    margin: "0 40px",
  },
  langButton: {
    margin: "0 40px 0 0",
  },
  userIconButton: {
    padding: 5,
  },
  pointsButton: {
    margin: "0 40px 0 0",
    padding: "4px 5px",
  },
  schoolIconButton: {
    padding: "0 7px 0 0",
    minWidth: 0,
    textTransform: "none",
  },
  warningBar: {
    minHeight: 50,
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
  },
  warningBarIcon: {
    marginRight: 50,
  },
  warningBarButton: {
    marginLeft: 50,
  },
  inactiveUserBar: {
    backgroundColor: amber[400],
    color: indigo[900],
  },
  accessDaysWarningBar: {
    backgroundColor: red[700],
    color: "white",
  },
  accessDemoDaysWarningBar: {
    backgroundColor: "#53AE67",
    color: "white",
  },
  sadIcon: {
    marginRight: 20,
  },
  inactiveUserBarText: {
    fontWeight: 600,
  },
  moreButton: {
    marginLeft: 20,
  },
  inactiveUserDialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 30,
  },
  inactiveUserDialogContentSadIcon: {
    width: 60,
    height: 60,
  },
  inactiveUserDialogContentText: {
    textAlign: "center",
    fontSize: 17,
    fontWeight: 600,
    color: indigo[900],
    marginTop: 20,
  },
  inactiveUserDialogContentCompanyName: {
    textAlign: "center",
    fontSize: 27,
    fontWeight: 600,
    marginTop: 10,
  },
  inactiveUserDialogContentCompanyContacts: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: 500,
    marginTop: 8,
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
}));

/** */
const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslate();
  const { signOut, company2 } = useAuth();
  const { company, subject, userInfo, leaderboard, getResources } = useBaseData();
  const [myPoints, setMyPoints] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const { alert } = useDialogs();

  useFavicon();

  const location = history.location.pathname;

  // Update levels on return back to home
  useEffectWithPreviousValues(
    ([prevLocation]) => {
      if (prevLocation && prevLocation !== "/" && location === "/") {
        getResources(["levels"]).then();
      }
    },
    [location],
  );

  /** @param {boolean} byButton */
  const reqNotifPermission = (byButton) => {
    if (!isNotificationsSupported() || !byButton) return;

    Notification.requestPermission().then();
  };

  /** */
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /** @param {Event} event */
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /** */
  const onProfile = () => {
    handleMenuClose();
    history.push("/profile");
  };

  /** */
  const onOrders = () => {
    handleMenuClose();
    history.push("/orders");
  };

  /** */
  const onPoints = () => {
    history.push("/leaderboard");
  };

  // update points
  useEffect(() => {
    if (leaderboard) {
      leaderboard.forEach((item) => {
        if (item.id === userInfo.id) {
          setMyPoints(item.points);
        }
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaderboard]);

  /** */
  const usernameClickHandler = () => {
    history.push("/profile");
  };

  /** */
  const schoolClickHandler = () => {
    history.push("/");
  };

  /** */
  const logoutClickHandler = () => {
    signOut();
  };

  const menuId = "account-menu";
  const isMenuOpen = Boolean(anchorEl);

  /** */
  const notificationButtonHandler = () => {
    alert({
      onClose: reqNotifPermission,
      title: t("notificationsAlert.permissionDefault.title"),
      message: t("notificationsAlert.permissionDefault.body"),
      buttonText: t("notificationsAlert.subscribe"),
    });
  };

  return (
    <>
      <ViewportMeta
        // Установка масштаба страницы, позволяющего увидеть весь контент на телефонах
        initialScale={0.4}
        // Возможность менять масштаб страницы двумя пальцами (Pinch to zoom)
        userScalable
      />
      <FlexboxVertical justifyContent="flex-start" alignItems="center" height="100vh">
        <PushNotification />
        <AppBar className={classes.appBar} position="relative">
          <Toolbar className={classes.toolbar} variant="dense">
            <FlexboxHorizontal data-tag="left edge" alignItems="center" color="white">
              <Button className={classes.schoolIconButton} color="inherit" onClick={schoolClickHandler}>
                <img src={company2?.logo.url || company.logo.url} alt="logo" height={40} />
                <Typography color="inherit">{company.name}</Typography>
              </Button>
            </FlexboxHorizontal>
            <CourseSelect buttonProps={{ className: classes.courseButton }} />
            <FlexboxHorizontal data-tag="right edge" color="white" alignItems="center">
              <LanguageSelect buttonProps={{ className: classes.langButton }} />
              {!IS_APP_STUDYLESS && !IS_APP_BEHAPPY && subject.showLeaderboard && (
                <Button className={classes.pointsButton} onClick={onPoints}>
                  <img className={classes.star} src={starIcon} alt="star" />
                  <Typography className={classes.leaderPoints} variant="body2">
                    {myPoints}
                  </Typography>
                </Button>
              )}
              {isNotificationsSupported() && Notification.permission === "default" && (
                <IconButton onClick={notificationButtonHandler} className={classes.notification}>
                  <div className={classes.notificationIcon}>
                    <NotificationsActiveIcon />
                  </div>
                </IconButton>
              )}
              <Button className={classes.userName} color="inherit" onClick={usernameClickHandler}>
                {userInfo.name}
              </Button>
              <IconButton className={classes.userIconButton} color="inherit" onClick={handleProfileMenuOpen}>
                <AccountCircleOutlinedIcon />
              </IconButton>
            </FlexboxHorizontal>
          </Toolbar>
        </AppBar>
        <InactiveUserBar classes={classes} />
        <AccessDaysWarningBar classes={classes} />
        <Menu
          anchorEl={anchorEl}
          id={menuId}
          keepMounted
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          getContentAnchorEl={null}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={onProfile}>
            <AccountCircleOutlinedIcon />
            <Typography className={classes.menuText}>{t("profile")}</Typography>
          </MenuItem>
          <MenuItem onClick={onOrders}>
            <CreditCard />
            <Typography className={classes.menuText}>{t("orders")}</Typography>
          </MenuItem>
          <MenuItem onClick={logoutClickHandler}>
            <PowerSettingsNewIcon />
            <Typography className={classes.menuText}>{t("logout")}</Typography>
          </MenuItem>
        </Menu>
        <EventsProvider>
          <NewsProvider>
            <HomeTabs />
          </NewsProvider>
        </EventsProvider>
        <LoadingBackdrop />
      </FlexboxVertical>
    </>
  );
};

export default Home;
