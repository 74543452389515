import React, { createContext, useContext, useEffect, useState } from "react";
import { I18n } from "react-polyglot";
import en from "./locales/en.js";
import ru from "./locales/ru.js";
import fr from "./locales/fr.js";
import uk from "./locales/uk.js";
import uz from "./locales/uz.js";
import { useUserPreferences } from "../Data/UserPreferencesProvider.jsx";
import { domains } from "../../constants.js";

/**
 * @return {Object}
 */
const selectLocales = () => {
  const domain = window.location.host;

  //only { en, uk } languages for chessLadder domain
  //use "domains.localhost" for localhost tests
  switch (domain) {
    case domains.chessLadder:
    case domains.studyless:
    case domains.studyless2:
      return { uk, en };
    case domains.localhost:
      return { uk, en, ru, fr, uz };
    default:
      return { uk, en, ru, fr, uz };
  }
};

/**
 * @param {string[]} localeCodes
 * @param {String} candidateLang
 * @returns {string}
 */
function getSupportedLang(localeCodes, candidateLang) {
  if (localeCodes.indexOf(candidateLang) > -1) {
    return candidateLang;
  } else {
    return localeCodes[0];
  }
}

/**
 * @typedef {Object} I18nProviderContext
 * @property {String} locale
 * @property {Function} setLocale
 * @property {string[]} localeCodes
 */

/** @type {React.Context<I18nProviderContext>} */
const i18nContext = createContext({
  locale: "uk",
  setLocale: () => {},
  localeCodes: [],
});

/** @returns {I18nProviderContext} */
export const useLocale = () => useContext(i18nContext);

/** @param {React.ReactNode} children */
const I18nProvider = ({ children }) => {
  const { lang, setLang } = useUserPreferences();
  const locales = selectLocales();
  const localeCodes = Object.keys(locales);
  const [messages, setMessages] = useState(locales[lang]);

  /** @param {String} newLocale */
  const setLocale = (newLocale) => {
    setLang(newLocale);
  };

  useEffect(() => {
    if (lang) {
      let langCandidate = getSupportedLang(localeCodes, lang);
      setLang(langCandidate);
      setMessages(locales[langCandidate]);
    } else {
      setLang(getSupportedLang(localeCodes, "uk"));
    }
  }, [lang]);

  if (!lang || !messages) return null;

  const value = {
    locale: lang,
    setLocale,
    localeCodes,
  };

  return (
    <i18nContext.Provider value={value}>
      <I18n locale={lang} messages={messages}>
        {children}
      </I18n>
    </i18nContext.Provider>
  );
};

export default I18nProvider;
