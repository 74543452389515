/*eslint-disable no-undef*/
export const API_URL = process.env.REACT_APP_API_URL;

export const DEFAULT_DOMAINS = process.env.REACT_APP_DEFAULT_DOMAINS.split("|");

export const IS_COMMON_APP = DEFAULT_DOMAINS.includes(window.location.host);

export const alphabets = {
  en: [
    "a", // 97
    "b", // 98
    "c", // 99
    "d", // 100
    "e", // 101
    "f", // 102
    "g", // 103
    "h", // 104
    "i", // 105
    "j", // 106
    "k", // 107
    "l", // 108
    "m", // 109
    "n", // 110
    "o", // 111
    "p", // 112
    "q", // 113
    "r", // 114
    "s", // 115
    "t", // 116
    "u", // 117
    "v", // 118
    "w", // 119
    "x", // 120
    "y", // 121
    "z", // 122
  ],
  fr: [
    "a", // 97
    "à", // 224
    "b", // 98
    "c", // 99
    "ç", // 231
    "d", // 100
    "e", // 101
    "é", // 233
    "è", // 232
    "ê", // 234
    "ë", // 235
    "f", // 102
    "g", // 103
    "h", // 104
    "i", // 105
    "î", // 238
    "ï", // 239
    "j", // 106
    "k", // 107
    "l", // 108
    "m", // 109
    "n", // 110
    "o", // 111
    "ô", // 244
    "œ", // 339
    "p", // 112
    "q", // 113
    "r", // 114
    "s", // 115
    "t", // 116
    "u", // 117
    "ù", // 249
    "û", // 251
    "ü", // 252
    "v", // 118
    "w", // 119
    "x", // 120
    "y", // 121
    "ÿ", // 255
    "z", // 122
  ],
  ru: [
    "а", // 1072
    "б", // 1073
    "в", // 1074
    "г", // 1075
    "д", // 1076
    "е", // 1077
    "ё", // 1105
    "ж", // 1078
    "з", // 1079
    "и", // 1080
    "й", // 1081
    "к", // 1082
    "л", // 1083
    "м", // 1084
    "н", // 1085
    "о", // 1086
    "п", // 1087
    "р", // 1088
    "с", // 1089
    "т", // 1090
    "у", // 1091
    "ф", // 1092
    "х", // 1093
    "ц", // 1094
    "ч", // 1095
    "ш", // 1096
    "щ", // 1097
    "э", // 1101
    "ю", // 1102
    "я", // 1103
  ],
  uk: [
    "а", // 1072
    "б", // 1073
    "в", // 1074
    "г", // 1075
    "ґ", // 1169
    "д", // 1076
    "е", // 1077
    "є", // 1108
    "ж", // 1078
    "з", // 1079
    "и", // 1080
    "і", // 1110
    "ї", // 1111
    "й", // 1081
    "к", // 1082
    "л", // 1083
    "м", // 1084
    "н", // 1085
    "о", // 1086
    "п", // 1087
    "р", // 1088
    "с", // 1089
    "т", // 1090
    "у", // 1091
    "ф", // 1092
    "х", // 1093
    "ц", // 1094
    "ч", // 1095
    "ш", // 1096
    "щ", // 1097
    "ю", // 1102
    "я", // 1103
  ],
  ar: [
    "ء", // 1569
    "أ", // 1571
    "ؤ", // 1572
    "إ", // 1573
    "ا", // 1575
    "ب", // 1576
    "ت", // 1578
    "ث", // 1579
    "ج", // 1580
    "ح", // 1581
    "خ", // 1582
    "د", // 1583
    "ذ", // 1584
    "ر", // 1585
    "ز", // 1586
    "س", // 1587
    "ش", // 1588
    "ص", // 1589
    "ض", // 1590
    "ط", // 1591
    "ع", // 1593
    "غ", // 1594
    "ف", // 1601
    "ق", // 1602
    "ك", // 1603
    "ل", // 1604
    "م", // 1605
    "ن", // 1606
    "ه", // 1607
    "و", // 1608
    "ي", // 1610
  ],
};

export const HOMEWORK_STATUS_COLORS = Object.freeze({
  wait_review: "#CDCFD2",
  on_review: "#CDCFD2",
  approved: "#53AE67",
  not_approved: "#F09837",
  not_approved_allow_update: "#F09837",
});

export const VIDEO_SOURCE_TYPE = {
  thirdParty: 1,
  skillzrun: 2,
};

export const domains = {
  localhost: "localhost:3002",
  chessLadder: "portal.chess-ladder.com.ua",
  studyless: "web.studyless.skillzrun.com",
  studyless2: "platform.study-less.com",
  beHappy: "app.proroditeli.com",
  leaderOnline: "web.leader-online.uz",
};

export const DATE_PERIOD_ACCESS = {
  closeMode: { never: "never", afterSeveralDays: "afterSeveralDays", exactlyAt: "exactlyAt" },
};

export const IS_APP_STUDYLESS =
  process.env.NODE_ENV === "production"
    ? window.location.host === domains.studyless || window.location.host === domains.studyless2
    : process.env.REACT_APP_STANDALONE_NAME === "studyless";

export const IS_APP_BEHAPPY =
  process.env.NODE_ENV === "production"
    ? window.location.host === domains.beHappy
    : process.env.REACT_APP_STANDALONE_NAME === "beHappy";

export const CONTENT_PAYMENT_TYPES = {
  html: "html",
  url: "url",
};

export const companiesToCheckToken = [954, 4];

export const largeRewardItemTypes = {
  learn_all_videos: "learn_all_videos",
  learn_all_theses: "learn_all_theses",
  learn_all_words: "learn_all_words",
  learn_all_exercises: "learn_all_exercises",
  unknown_all_videos: "unknown_all_videos",
  unknown_all_words: "unknown_all_words",
  unknown_all_exercises: "unknown_all_exercises",
  learn_deck: "learn_deck",
  learn_branch: "learn_branch",
  learn_mission_task: "learn_mission_task",
  learn_mission: "learn_mission",
};

export const languageCountryMap = {
  en: "GB",
  uk: "UA",
  fr: "FR",
  ru: "RU",
  uz: "UZ",
};
