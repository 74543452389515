export default {
  title: {
    login: "%{school} - Kirish",
    registration: "%{school} - Ro'yxatdan o'tish",
    subjects: "%{school} - Fanni tanlash",
    subject: "%{school} - Fan",
    offer: "%{school} - Taklif",
    home: "%{school} - Shaxsiy sahifa",
    dictionary: "%{school} - Lug'at",
    events: "%{school} - Darslar",
    news: "%{school} - Yangiliklar",
    profile: "%{school} - Profil",
    learn: "%{school} - Ta'lim",
    pay: "%{school} - Fan uchun to'lov",
    checkInSystem: "Email-ingizni ko'rsating",
    setPassword: "Kirish uchun parolingizni tering.",
    createAccount: " Akkauntingiz ma'lumotlarini ko'rsating/yangilang.",
  },
  email: "Email",
  personalLink: "Shaxsiy havola",
  login: "Login",
  name: "Ism",
  firstLastName: "Familiya va ism", // todo
  phone: "Telefon",
  formatPhone: "998... formatida telefon raqamingiz", // todo for leader-online
  aboutMe: "Men haqimda",
  fullAge: "Yoshingiz (to‘liq)", // todo
  errorAge: "noto'g'ri yosh", // todo
  notNumber: "Qiymat raqam bo'lishi kerak", // todo
  registration: "Ro'yxatdan o'tish",
  password: "Parol",
  currentPassword: "Joriy parol",
  newPassword: "Yangi parol",
  confirmPassword: "Parolni takrorlang",
  confirmNewPassword: "Yangi parolni takrorlang",
  forgotPassword: "Parolni unutdingizmi?",
  passwordsMatch: "Parollar mos tushmayapti",
  resetPassword: "Parol muvaffaqiyatli o'chirildi, elektron pochtangizni tekshiring.",
  resetConfirm: "Parolni o'chirishni xohlaysizmi?",
  enter: "kirish",
  studentEnter: "O'quvchilar uchun kirish",
  check: "Keyingisi",
  emailIsNotRegistered: "Bunday manzilli foydalanuvchi mavjud emas",
  logout: "Chiqish",
  cancel: "Bekor qilish",
  yes: "Ha",
  no: "Yo'q",
  course: "Kurs",
  dictionary: "Lug'at",
  events: "Darslar",
  news: "Yangiliklar",
  words: "So'zlar",
  wordsAreHere: "Bu so'zlar bu yerda joylashgan:",
  allWords: "Barcha so'zlar",
  phrases: "Iboralar",
  videos: "Video",
  exercises: "Mashqlar",
  theory: "Nazariya",
  theoryAlt: "Kospekt",
  theoryAdditionalFile: "Qo'shimcha faylni yuklab olish (%{filename})",
  topic: "Topik",
  lesson: "Dars",
  profile: "Profil",
  orders: "Haridlar",
  payment: "To'lov",
  leaderboard: "Liderlar",
  time: "Vaqt",
  points: "Ballar",
  courses: "Mening kurslarim",
  currentLang: {
    en: "English",
    ru: "Rus tili",
    fr: "Français",
    uk: "Українська",
  },
  loading: "Yuklash...",
  noTheory: "Ushbu dars uchun nazariy ma'lumotlar yo'q",
  startTraining: "Mashqni boshlash",
  missions: "Vazifalar",
  branches: "Bo'limlar",
  easy: "Oson",
  expert: "Ekspert",
  wordToLearn: "Keyinroq o'rganish",
  iKnowWord: "Men bu so'zni bilaman",
  videoVocabulary: "Video leksika",
  passed: "o'tilgan",
  unsupportedExercise: "Bu turdagi mashqni (%{type}) hali bajarib bo'lmaydi",
  changeCourse: "Kursni almashtirish",
  currentCourse: "Joriy kurs",
  allSubjects: "Barcha fanlar",
  subject: "Fanlar",
  group: "Guruh",
  start: "Boshlash",
  chooseSubject: "O'rganishni xohlagan fanni tanlang",
  hourValue: "%{value} ч",
  completed: "Yakunlandi",
  notCompleted: "Yakunlanmadi",
  paidBranch: "Bu mavzu pulli. Iltimos,  o'rganish uchun uni sotib oling.",
  buyBranch: "Mavzuni %{price} %{currency} ga sotib olish",
  paidSubject: "Bu fan pulli. Iltimos,  o'rganish uchun uni sotib oling.",
  buySubject2: "Fanni %{price} %{currency} ga sotib olish",
  decksItemNotOpen: "Ushbu darsni ochish uchun siz avvalgi darsdan o'tishingiz kerak",
  decksItemNotPurchasedBranch: "The topic of this lesson is blocked",
  decksItemNotOpenLevel: "Darsni ochish uchun oldingi darajadan o'ting",
  decksItemNotOpenBranch: "Ushbu darsni ochish uchun siz avvalgi mavzudan o'tishingiz kerak",
  deckAccessDenied: "Ushbu dars siz uchun hali mavjud emas",
  openOnDate: "ochiladi %{date}", // todo
  levelAccessIsDenied: "Ushbu darajadagi materiallar siz uchun mavjud emas",
  youAreDeactivated: "Akkauntingiz o'chirilgan, iltimos, maktabingiz bilan bog'laning",
  youAreDeactivatedShort: "Sizning akkauntingiz o'chirilgan",
  youAreHaveNotPaidOffers: "Sizda to'lanmagan takliflaringiz mavjud",
  yourCertificates: "Sizning sertifikatlaringiz",
  emptyDeck: "Dars bo'sh",
  nextDeck: "Keyingi dars",
  next: "Keyingisi",
  proceed: "Davom ettirish",
  skip: "O'tkazib yuborish",
  more: "Yana",
  exit: "Chiqish",
  details: "Batafsil",
  go: "O'tish",
  profileEdit: "Profilni tahrirlash",
  userInfo: "Foydalanuvchi haqida ma'lumot",
  of: "dan",
  back: "Orqaga",
  levelPackExist: "Sizda ushbu darajalar to'plami mavjud",
  awaitingPayment: "To'lovni kutmoqda",
  bought: "Sotib olingan",
  boughtUntilDate: "%{endDate} gacha sotib olingan",
  availableUntilDate: "%{endDate} gacha kirishingiz mavjud",
  showMore: "ko'proq ko'rsatish",
  hide: "o'ramoq",
  communication: "Maktab bilan bog'lanish",
  askQuestion: "Savol berish",
  noDescription: "Tavsiflash mavjud emas",
  onePerMonth: "1 per Month", //toDo
  semiannually: "Semiannually", //toDo
  onceAYear: "Once a year", //toDo
  stopSubscribe: "Stop Subscribe", // todo
  enable: "Enable Subscribe", // todo
  expired: "Expired", //toDo
  buySubject: "fanni sotib olish",
  buyOffer: "Taklifni sotib olish",
  subscribeOffer: "Subscribe offer", // todo
  firstPayment: "Yangi to'lov",
  accessDaysWarning: "%{smart_count} kun qoldi ||||%{smart_count} kun qoldi",
  accessDemoDaysWarning: "Demo: %{smart_count} kun qoldi |||| Demo: %{smart_count} kun qoldi",
  closeButton: "Yopish",
  existOffer: "There is already an unpaid offer", // todo
  Exercises: {
    tabTitle: "Mashqlar",
    skip: "O'tkazib yuborish",
    check: "Tekshirish",
    typeAnswer: "Javobingizni kiriting",
    next: "Keyingisi",
    rightAnswerWas: "To'g'ri javob:",
    congratulation: "Tabriklaymiz!",
    congratText: "Siz ushbu darsga oid barcha mashqlarni o'rgandingiz!",
    wrongAnswer: "Sizning javobingiz noto'g'ri",
    answerSaved: "Javob saqlandi",
    commentToAnswer: "Tanlangan javobga izoh:", // todo
    appendix: {
      pdf: {
        tooltipButton: "Qo'shimcha materiallar",
      },
    },
    type12: {
      title: "Qo'shimcha ma'lumot bilan tanishib chiqing", // todo
    },
    type11: {
      title: {
        select: "To'g'ri javobni tanlang",
        keyboard: "To'g'ri javobni yozing",
      },
    },
    type9: {
      title: {
        select: "Kerakli so'zni tanlab, bo'shliqlarni to'ldiring",
        keyboard: "Bo'shliqlarni to'ldiring",
      },
    },
    type8: {
      title: "Juftlarni toping",
    },
    type7: {
      title: {
        text: "Javobingizni yozing",
        audio: "Audio javobingizni yozing",
      },
      recordingIsNotSupported: "Ovoz yozish brauzeringiz tomonidan qo'llab-quvvatlanmaydi",
      audioRecordingError: "Ovoz yozishda xatolik: ",
      inputLabel: "Sizning javobingiz",
      addPhoto: "Rasm/video qo'shing (5 donagacha)",
      cameraInitialization: "Ishga tushirish...",
      change: "O'zgartirish",
      codeToApprove: "Parol",
      homework: {
        teacherData: {
          audio: "O'qituvchining audio izohi",
          audioComment: "O'qituvchining ovozli izohi",
        },
      },
      addFiles: "Fayl qo'shish",
      limitsHelperText: "Matn uzunligi  kamida %{count} harf bo'lishi kerak",
    },
    type6: {
      title: "Нарисуйте элемент",
      checkButtonText: "Tushunarli!",
    },
    type3: {
      title: "Elementlarni to'g'ri tartibda joylashtiring",
      hint: "Quyida joylashgan elementlarni bu yerga olib o'ting",
    },
    type5: {
      title: "Elementlarni tanlash",
      hint: "Ekranning pastki qismidagi kerakli elementlarni tanlang (tanlash uchun — ularni bosish kifoya)",
    },
    type2: {
      title: "Tushirib qoldirilgan so'zni tanlang",
    },
  },
  Events: {
    headNotice: "Dars vaqti sizning vaqt mintaqangizga qarab ko'rsatiladi",
    title: "Ushbu haftadagi darslar",
    startTime: "Boshlanish vaqti",
    group: "Guruh",
    persons: "%{smart_count} kishi |||| %{smart_count} kishi",
    leader: "Olib boruvchi",
    tag: "Toifa",
    joinedCheckbox: "Ro'yxatdan o'tish bilan",
    joined: "Tanlandi:",
    interested: "Ro'yxatdan o'tish",
    enter: "Kirish",
    limitPopupInfo: "Siz darsga ro'yxatdan o'tishning limitini tugatdingiz",
    eventHasStarted: "Dars boshlanib bo'ldi!",
    registrationNotice: "Siz muvaffaqiyatli ro'yxatdan o'tdingiz! Kirish tugmasi %{date_and_time} da faol bo'ladi. Bu vaqtdan keyin siz dars uchun ro'yxatdan o'tishni bekor qila olmaysiz",
    registrationNoticeNoUrl: "Siz muvaffaqiyatli ro'yxatdan o'tdingiz! %{date_and_time} dan keyin siz dars uchun ro'yxatdan o'tishni bekor qila olmaysiz",
    cancelRegistration: "Siz chindan ham ro'yxatdan o'tishni bekor qilmoqchimisiz?",
    calendarLink: "Darsni Google taqvimga qo'shish",
  },
  statistic: {
    title: "Statistika",
    points: "ballar",
    phrases: "iboralar",
    missions: "vazifalar",
    words: "lug'at",
    time: "vaqt",
  },
  status: {
    wait_review: "Tekshirishi kutilmoqda",
    on_review: "Tekshiruvda",
    approved: "Qabul qilindi",
    not_approved: "Rad etildi",
    not_approved_allow_update: "Rad etildi (Qaytadan bajrish)",
    ai_reviewed: "Ai tomonidan tekshirildi",
  },
  homeworks: {
    tabName: "Uyga vazifalar",
    todo: "Bajarish uchun",
    done: "Bajarilganlar",
    topic: "Mavzu",
    lesson: "Dars",
    onlyInMobileApp: "Faqat mobil ilovada mavjud",
    todoHomework: "TOPSHIRIQ TEKSHIRUVDA",
    postToDoButton: "Yuborish",
    types: {
      homework_todo: "ToDo",
      exercise: "Exercise",
    },
  },
  exam: {
    popupTitle: "Imtihonli dars",
    popupDescr1: "Bu darsda imtihon mavjud. Avval, barcha qo'shimcha materiallarni o'rganing va shundan keyingina mashqlarni bajarishga o'ting.",
    popupDescr2: `"Mashqlar" yorlig'iga kirganingizdan so'ng, vaqtni hisoblash boshlanadi.`,
    popupDescr3: '"Nazariya" va "Video" yorliqlari imtihon vaqtini hisoblashni boshlamaydi.',
    cancelButton: "Keyingi safar",
    positiveButton: "Tushunarli",
    questions: "%{smart_count} savol |||| %{smart_count} savollar",
    oneAttempt: "Javob uchun bitta urinish",
    timeLimit: "Vaqt chegarasi: %{limit} minut",
    noPause: "Imtihonni to'xtatib bo'lmaydi",
    progress: "Savol №%{current} dan %{total}",
    closeButton: "Yopish",
    nextButton: "Davom ettirish",
    finishedPopupTitle: "Imtihon natijasi",
    examPassed: "Imtihondan o'tildi",
    examNotPassed: "Imtihondan o'tilmadi",
    waitHomeworks: "%{smart_count} savol tekshiruvda |||| %{smart_count} savol tekshiruvda",
  },
  subjects: {
    unavailable: "Ayni paytda fan mavjud emas",
  },
  orderStatus: {
    approved: "Qabul qilindi",
    canceled: "Rad etildi",
    pending: "Kutilmoqda",
  },
  notificationsTypes: {
    event: "Dars",
    invoice: "Hisob",
    news: "Yangiliklar",
    homework: "homework", //todo
    homework_todo: "homework_todo", //todo
    type: {
      exercise: "Homework", //todo
      homework_todo: "Homework To do", //todo
    },
  },
  notificationsAlert: {
    subscribe: "Bildirishlarni yoqish",
    unsubscribe: "Bildirishlarni o'chirish",
    instructions: "Manzil satrining chap tomonidagi 🔒qulfni bosing va rasmda ko'rsatilgandek bildirishnomaga ruxsatni o'zgartiring yoki brauzerning umumiy sozlamalarini o'zgartiring.",
    permissionDefault: {
      title: "Bildirishnomalarga obuna bo'ling 🔔",
      body: "UV holatining  dars boshlanishi, yangiliklar, yangi topshiriqqa o'zgarishi haqidagi ogohlantirishlar. Hech qanday reklama yo'q, bildirishnomalar faqat sizning mashg'ulotlaringiz haqida!",
    },
    permissionDenied: {
      title: "Hozir bildirishlar o'chirilgan ❌",
      body: "Siz ilgari bildirishnomalardan voz kechgansiz. Ularsiz siz UV holatining  dars boshlanishi holatiga o'zgarishi haqida o'z vaqtida bilib olomaysiz. Bildirishnomalarni yoqish uchun quyidagi amallarni bajaring:",
    },
    permissionGranted: {
      title: "Hozir bildirishnomalar yoqilgan ✔",
      body: "Siz chindan ham bildirishnomalar olishni xohlamaysizmi? Ularsiz siz UV holatining dars boshlanishi holatiga o'zgarishi haqida o'z vaqtida bila olmaysiz. Bildirishnomalarni o'chirish uchun quyidagi amallarni bajaring:",
    },
  },
  feedbackDialog: {
    sendFeedback: "Fikr-mulohaza yuborish", // todo
    placeholder: "Ta'surotlaringiz bilan bo'lishing", // todo
    defaultInstruction: "Baholash uchun slayderdan foydalaning", // todo
    eventInstruction: "Dars qanday o'tkanini baholang", // todo
    submitError: "Iltimos bahoni tanlang", // todo
    submitSuccess: "Rahmat! Sizning fikr-mulohazangiz muvaffaqiyatli yuborildi", // todo
    wasNotPresent: "Men darsda qatnashmadim", // todo
    dateOfEvent: "Dars sanasi:", // todo
  },
  congratulation: {
    mission: "%{name} sizni tabriklaymiz, vazifa bajarildi!",
  },
  accountNotFound: "Ushbu elektron pochta manzili hech qanday ta'lim tashkiloti bilan bog'lanmagan. Maktabingiz ma'muriyati bilan bog'laning",
};
