import DOMPurify from "dompurify";
import { v4 as uuidv4 } from "uuid";
import { DATE_PERIOD_ACCESS } from "../constants.js";

export const VideoType = Object.freeze({
  YOUTUBE: 1,
  VIMEO: 2,
  SKILLZRUN: 3,
  UNSUPPORTED: 0,
});

/**
 * @param {Number} accum
 * @param {Number} value
 * @returns {Number}
 */
export function numSumReducer(accum, value) {
  return accum + value;
}

/**
 * @param {String} videoUrl
 * @returns {String}
 */
export function makeEmbedVideoUrl(videoUrl) {
  if (videoUrl.indexOf("www.youtube.com/") > 0) {
    const theMatch = videoUrl.match(/watch\?v=.+/);
    if (theMatch && theMatch.length > 0) {
      const id = theMatch[0].replace("watch?v=", "");
      return `https://www.youtube.com/embed/${id}`;
    }
  }
  if (videoUrl.indexOf("vimeo.com/") > 0) {
    const theMatch = videoUrl.match(/vimeo.com\/\d+/);
    if (theMatch && theMatch.length > 0) {
      const id = theMatch[0].replace("vimeo.com/", "");
      return `https://player.vimeo.com/video/${id}`;
    }
  }
  return "";
}

/**
 * @param {String} videoUrl
 * @returns {{id: String, videoType: Number}}
 */
export function extractVideoId(videoUrl) {
  if (/^https?:\/\/www\.youtube\.com\/watch/.test(videoUrl)) {
    const id = videoUrl.match(/[?&]v=[^?^&]+/)[0].replace(/[?&]v=/, "");

    return {
      id,
      videoType: VideoType.YOUTUBE,
    };
  }
  if (/^https?:\/\/youtu\.be\//.test(videoUrl)) {
    const id = videoUrl.match(/youtu\.be\/[^?^&]+/)[0].replace(/youtu\.be\//, "");
    const startTimeMatch = videoUrl.match(/[?]t=[^?^&]+/);

    const startTime = startTimeMatch ? startTimeMatch[0].replace("?t=", "") : "";

    return {
      id,
      videoType: VideoType.YOUTUBE,
      startTime,
    };
  }
  if (/^https?:\/\/(www\.)?youtube\.com\/(shorts|live)/.test(videoUrl)) {
    const id = videoUrl.match(/youtube\.com\/(shorts|live)\/[^?^&]+/)[0].replace(/youtube\.com\/(shorts|live)\//, "");

    return {
      id,
      videoType: VideoType.YOUTUBE,
    };
  }
  if (videoUrl.indexOf("vimeo.com/") > 0) {
    const theMatch = videoUrl.match(/vimeo.com\/\d+/);
    if (theMatch && theMatch.length > 0) {
      const id = theMatch[0].replace("vimeo.com/", "");
      return {
        id,
        videoType: VideoType.VIMEO,
      };
    }
  }
  if (/^http.?:\/\/.*skillzrun\.com\/media\/video/.test(videoUrl)) {
    return {
      id: "",
      videoType: VideoType.SKILLZRUN,
    };
  }
  // eslint-disable-next-line prettier/prettier
  if (/^http:\/\/(localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}):\d*(\/+|\\+)media(\/+|\\+)video/.test(videoUrl)) {
    // for debug
    return {
      id: "",
      videoType: VideoType.SKILLZRUN,
    };
  }
  return {
    id: "",
    videoType: VideoType.UNSUPPORTED,
  };
}

/**
 * @param {Number} totalSeconds
 * @returns {String}
 */
export function formatTime(totalSeconds) {
  let _totalSeconds = Math.floor(totalSeconds);
  let hours = Math.floor(_totalSeconds / 3600).toString();

  const hoursNumber = Math.floor(_totalSeconds / 3600);
  if (hoursNumber > 0) {
    _totalSeconds = _totalSeconds - hoursNumber * 3600;
  }

  let minutes = Math.floor(_totalSeconds / 60).toString();
  let seconds = (_totalSeconds % 60).toString();

  if (minutes.length < 2) {
    minutes = "0" + minutes;
  }
  if (seconds.length < 2) {
    seconds = "0" + seconds;
  }
  if (hours.length < 2) {
    hours = "0" + hours;
  }
  return hours === "00" ? `${minutes}:${seconds}` : `${hours}:${minutes}:${seconds}`;
}

/**
 * @param {String} time
 * @returns {Number}
 */
export function parseTime(time) {
  const parts = time.split(":");
  const minutes = Number.parseInt(parts[0]);
  const seconds = Number.parseInt(parts[1]);
  return minutes * 60 + seconds;
}

/**
 * @param {String} dateString
 * @returns {String}
 */
export function convertToCompactUTC(dateString) {
  return dateString.replace(/-|:|\.\d{3}/g, "");
}

/**
 * @param {String} src
 * @returns {Promise<HTMLImageElement>}
 */
function loadImage(src) {
  return new Promise((r) => {
    const image = new Image();
    image.onload = () => r(image);
    image.src = src;
  });
}

/**
 * @param {File} file
 * @returns {Promise<String>}
 */
function loadFile(file) {
  return new Promise((r) => {
    const reader = new FileReader();
    reader.onload = (readerEvent) => r(readerEvent.target.result);
    reader.readAsDataURL(file);
  });
}

/**
 * @param {String} dataURL
 * @returns {Blob}
 */
export function dataURLToBlob(dataURL) {
  const BASE64_MARKER = ";base64,";
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    const parts = dataURL.split(",");
    const contentType = parts[0].split(":")[1];
    const raw = parts[1];

    return new Blob([raw], { type: contentType });
  }

  const parts = dataURL.split(BASE64_MARKER);
  const contentType = parts[0].split(":")[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;

  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
}

/**
 * @param {File} imageFile
 * @returns {Promise<File>}
 */
export async function resizeImage(imageFile) {
  const maxPixelCount = 3 * 1000 * 1000;
  const image = await loadImage(await loadFile(imageFile));
  let width = image.width,
    height = image.height;
  if (width * height > maxPixelCount) {
    const aspect = width / height;
    const canvas = document.createElement("canvas");
    const sq = Math.sqrt(maxPixelCount / aspect);
    width = sq * aspect;
    height = sq;
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL(imageFile.type);
    const resizedImage = dataURLToBlob(dataUrl);
    canvas.remove();
    return new File([resizedImage], imageFile.name);
  } else {
    return imageFile;
  }
}

/**
 * @param {String} text
 * @param {String} font
 * @returns {number}
 */
export function calcTextWidth(text, font) {
  let canvas = calcTextWidth.canvas || (calcTextWidth.canvas = document.createElement("canvas"));
  let context = canvas.getContext("2d");
  context.font = font;
  let metrics = context.measureText(text);
  return metrics.width;
}

/**
 * @param {Number} pause - pause in milliseconds
 * @returns {Promise<unknown>}
 */
export function delay(pause) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, pause);
  });
}

/**
 * @param {String} text
 * @returns {String}
 */
export function replaceUrlsWithLinks(text) {
  const urlPattern = /(?<!<a[^>]*?)\b(https?|ftp):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|](?![^<]*<\/a>)/g;
  const newText = text.replace(urlPattern, (url) => {
    return `<a href="${url}" target="_blank">${url.slice(0, 30)}${url.length > 31 ? "..." : ""}</a>`;
  });
  return newText;
}

/**
 * @param {String} text
 * @returns {String}
 */
export function replaceUrlsOutsideTags(text) {
  // Разбиваем HTML на теги и текст между ними
  const parts = text.match(/<[^>]*>|[^<]+/g);

  // Регулярное выражение для поиска URL
  const urlRegex = /(https?:\/\/[^\s<]+)/g;

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];

    // Если это не тег, обрабатываем текст
    if (!part.startsWith("<")) {
      parts[i] = part.replace(urlRegex, function (url) {
        return `<a href="${url}" target="_blank">${url.slice(0, 30)}${url.length > 31 ? "..." : ""}</a>`;
      });
    }
  }

  return parts.join("");
}

/**
 * @param {string} htmlText
 * @return {string}
 */
export function escapeHTML(htmlText) {
  return htmlText
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

/**
 * @param {string} inputString
 * @return {string}
 */
export function stripHtmlTags(inputString) {
  if (!inputString) return inputString;

  return inputString.replace(/<[^>]*>/g, " ").replace(/\s{2,}/g, " ");
}

/**
 * @param {?Offer} offer
 * @param {function} setIsOfferRepeat
 */
export function checkOfferRepeat(offer, setIsOfferRepeat) {
  if (!offer) {
    setIsOfferRepeat(false);
  } else {
    const isRepeat =
      offer.access.closeMode === DATE_PERIOD_ACCESS.closeMode.afterSeveralDays ||
      offer.access.closeMode === DATE_PERIOD_ACCESS.closeMode.exactlyAt;
    setIsOfferRepeat(isRepeat);
  }
}

/**
 * @param {?LoginResponse|UserInfo} user
 * @param {function} setEndDate
 */
export function calculateEndDateOfAccess(user, setEndDate) {
  const subjectAccess = user?.subjectAccess;
  if (!subjectAccess) {
    setEndDate("no date");
  } else {
    const { lang } = localStorage.getItem("user_prefs");
    let date;
    switch (subjectAccess.closeMode) {
      case DATE_PERIOD_ACCESS.closeMode.afterSeveralDays:
        date = subjectAccess.startDate + subjectAccess.dayCount * 24 * 60 * 60 * 1000;
        setEndDate(new Date(date).toLocaleDateString(lang, { dateStyle: "medium" }));
        break;
      case DATE_PERIOD_ACCESS.closeMode.exactlyAt:
        date = subjectAccess.endDate;
        setEndDate(new Date(date).toLocaleDateString(lang, { dateStyle: "medium" }));
        break;
      default:
        setEndDate("");
    }
  }
}

/**
 * @param {?LoginResponse|UserInfo} user
 * @param {function} setDaysToEnd
 */
export function calculateDaysToEndOfAccess(user, setDaysToEnd) {
  const subjectAccess = user?.subjectAccess;
  if (!subjectAccess) {
    setDaysToEnd("");
  } else {
    let date;
    let daysToEnd;
    switch (subjectAccess.closeMode) {
      case DATE_PERIOD_ACCESS.closeMode.afterSeveralDays:
        date = subjectAccess.startDate + subjectAccess.dayCount * 24 * 60 * 60 * 1000;
        daysToEnd = (date - Date.now()) / (24 * 60 * 60 * 1000);
        setDaysToEnd(Math.ceil(daysToEnd));
        break;
      case DATE_PERIOD_ACCESS.closeMode.exactlyAt:
        date = subjectAccess.endDate;
        daysToEnd = (date - Date.now()) / (24 * 60 * 60 * 1000);
        setDaysToEnd(Math.ceil(daysToEnd));
        break;
      default:
        setDaysToEnd("");
    }
  }
}

/**
 * @param {string} url
 * @returns {string}
 */
export function extractFileNameFromUrl(url) {
  const Url = new URL(url);
  return Url.pathname.split("/").pop();
}

/**
 * @param {string} data
 * @return {{__html: *}}
 */
export function sanitizedData(data) {
  return {
    __html: DOMPurify.sanitize(data, {
      ADD_TAGS: ["iframe"],
      ADD_ATTR: ["src", "height", "width", "frameborder"],
    }),
  };
}

/**
 * @param {number} type
 * @param {string} answerType
 * @return {string}
 */
export function makeDefaultTitle(type, answerType) {
  let translateKey = "";
  switch (type) {
    case 3:
      translateKey = "Exercises.type3.title";
      break;
    case 5:
      translateKey = "Exercises.type5.title";
      break;
    case 6:
      translateKey = "Exercises.type6.title";
      break;
    case 7:
      translateKey = `Exercises.type7.title.${answerType}`;
      break;
    case 8:
      translateKey = "Exercises.type8.title";
      break;
    case 9:
      translateKey = `Exercises.type9.title.${answerType}`;
      break;
    case 11:
      translateKey = answerType === "keyboard" ? "Exercises.type11.title.keyboard" : "Exercises.type11.title.select";
      break;
    case 12:
      translateKey = "Exercises.type12.title";
      break;
  }

  return translateKey;
}

/**
 * @param {string} text
 * @return {string}
 */
export function parseTextWithBbcode(text) {
  const regexBbcode = /\[spoiler=([\s\S]*?)\s+id=([a-zA-Z0-9]+)]([\s\S]*?)\[\s*\/spoiler\]/g;
  const regexSlash = /\/\/\/([\s\S]*)/;

  const resultForBbcode = text.replace(
    regexBbcode,
    "<a href='#' class='spoiler' id=$2>$1</a> <div id='div-$2' style='display: none'>$3</div>",
  );
  const slashId = uuidv4().slice(0, 8);
  const newText = resultForBbcode.replace(
    regexSlash,
    `<a href='#' class='slash' id=${slashId}></a> <div id='div-${slashId}' style='display: none'>$1</div>`,
  );

  return newText;
}
