import React, { useState, useEffect } from "react";
import { Box, makeStyles, withStyles, Tabs, Tab, Typography, IconButton, Tooltip } from "@material-ui/core";
import _ from "lodash";
import { useTranslate } from "react-polyglot";
import Theory from "./Theory.jsx";
import WordsLearning from "./WordsLearning.jsx";
import Videos from "./Videos.jsx";
import { useCourseProvider } from "../../../Providers/Data/CourseProvider.jsx";
import { useLearnProcess } from "../../../Providers/Data/LearnProcessProvider.jsx";
import Exercises from "./Exercises/Exercises.jsx";
import useExtractRouteParamInt from "../../../hooks/useExtractRouteParamInt.js";
import useEffectWithPreviousValues from "../../../hooks/useEffectWithPreviousValues.js";
import questionIcon from "../../../assets/writing-white.png";
import { IS_APP_BEHAPPY, largeRewardItemTypes } from "../../../constants.js";
import { useDialogs } from "../../../Providers/Common/DialogsProvider.jsx";

const StyledTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.palette.primary.dark,
    height: 8,
  },
}))((props) => <Tabs {...props} />);

const StyledTab = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark,
    opacity: 1,
    fontSize: 15,
    fontWeight: 300,
    "&[disabled]": {
      display: "none",
    },
  },
  selected: {
    fontWeight: 900,
  },
}))((props) => <Tab {...props} />);

const useStyles = makeStyles((theme) => ({
  deckContentRoot: {
    margin: "32px 0 30px",
    minHeight: 700,
    height: 1,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      margin: "32px auto",
      width: 855,
    },
  },
  emptyDeck: {
    textAlign: "center",
    marginTop: 100,
  },
  questionIcon: {
    position: "fixed",
    right: 20,
    bottom: 20,
    backgroundColor: "#289C5D",
    width: 60,
    height: 60,
    [theme.breakpoints.up("lg")]: {
      right: 100,
      bottom: 30,
    },
    "&:hover": {
      backgroundColor: "#289C5D",
    },
  },
  questionImg: {
    width: 45,
    paddingLeft: "5px",
  },
}));

/** */
const DeckContent = () => {
  const classes = useStyles();
  const t = useTranslate();
  const { currentDeck, currentBranch } = useCourseProvider();
  const { rewards } = useLearnProcess();
  const { alert } = useDialogs();
  const wordId = useExtractRouteParamInt("/learn/:deckId/:wordId", "wordId");
  const exerciseId = useExtractRouteParamInt("/learn/:deckId/exercise/:exerciseId", "exerciseId");
  const [tabIndex, setTabIndex] = useState(0);
  const [missionRewards, setMissionRewards] = useState([]);
  const theoryLabel = IS_APP_BEHAPPY ? t("theoryAlt") : t("theory");

  // reset tab index to 0 if there is no data in current deck for currently selected tab
  useEffectWithPreviousValues(
    /** @param {?Deck} prevDeck */
    ([prevDeck]) => {
      if (!currentDeck) {
        return;
      }
      if (prevDeck && prevDeck.id === currentDeck.id) {
        return;
      }
      if (wordId && currentDeck.words.count > 0) {
        setTabIndex(2);
        return;
      }
      if (exerciseId && currentDeck.exercises.items.length > 0) {
        setTabIndex(3);
        return;
      }
      if (currentDeck.videos.count > 0) {
        setTabIndex(0);
      } else if (currentDeck.theory.html || currentDeck.theory.pdf) {
        setTabIndex(1);
      } else if (currentDeck.words.count > 0) {
        setTabIndex(2);
      } else if (currentDeck.exercises.items.length > 0) {
        setTabIndex(3);
      } else {
        setTabIndex(0);
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [currentDeck, wordId, exerciseId],
  );

  useEffect(() => {
    if (rewards?.large) {
      if (rewards.large.items) {
        setMissionRewards(rewards.large.items.filter((item) => item.type === largeRewardItemTypes.learn_mission));
      }
    }
  }, [rewards]);

  useEffect(() => {
    if (missionRewards.length > 0) {
      alert({
        onClose: onCloseRewards,
        title: t("congratulation.mission", { name: missionRewards[0].content }),
        message: missionRewards[0].congratulationText,
        maxWidth: "md",
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionRewards]);

  /** */
  const onCloseRewards = () => {
    const newRewards = _.cloneDeep(missionRewards);
    newRewards.shift();
    setMissionRewards(newRewards);
  };

  /**
   * @param {*} _
   * @param {Number} newValue
   */
  const onTabClick = (_, newValue) => {
    setTabIndex(newValue);
  };

  if (!currentDeck || !currentDeck.open || (currentBranch && !currentBranch.open)) {
    return null;
  }

  return (
    <Box className={classes.deckContentRoot}>
      <StyledTabs value={tabIndex} onChange={onTabClick} centered>
        <StyledTab label={t("videos")} disabled={currentDeck.videos.count === 0} />
        <StyledTab
          label={theoryLabel}
          disabled={!currentDeck.theory.html && !currentDeck.theory.pdf && !currentDeck.theory.additionalFileUrl}
        />
        <StyledTab label={t("words")} disabled={currentDeck.words.count === 0} />
        <StyledTab label={t("Exercises.tabTitle")} disabled={currentDeck.exercises.items.length === 0} />
      </StyledTabs>
      {tabIndex === 0 && <Videos />}
      {tabIndex === 1 &&
        (currentDeck.theory.html || currentDeck.theory.pdf || currentDeck.theory.additionalFileUrl) && <Theory />}
      {tabIndex === 1 &&
        !currentDeck.theory.html &&
        !currentDeck.theory.pdf &&
        !currentDeck.theory.additionalFileUrl && (
          <Typography className={classes.emptyDeck}>{t("emptyDeck")}</Typography>
        )}
      {tabIndex === 2 && <WordsLearning />}
      {tabIndex === 3 && <Exercises />}
      {currentDeck.communicationLink && (
        <Tooltip title={t("askQuestion")} arrow>
          <IconButton className={classes.questionIcon} href={currentDeck.communicationLink} target="_blank">
            <img src={questionIcon} alt="question icon" className={classes.questionImg} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default DeckContent;
